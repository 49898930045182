import styled from 'styled-components';
import 'react-responsive-modal/styles.css';
import NewLogo from './assets/images/new_logo.svg';
import Twitter from './assets/images/twitter.svg';
import Telegram from './assets/images/telegram.svg';
import Discord from './assets/images/discord.svg';
import Medium from './assets/images/medium.svg';
import GitHub from './assets/images/gitHub.svg';
import Youtube from './assets/images/youtube.svg';
import BackersMecha from './assets/images/mecha.svg';
import BackersKr1 from './assets/images/kr1.svg';
import BackersP2p from './assets/images/p2p.svg';
import BackersArr from './assets/images/arr.svg';
import BackersOrth from './assets/images/orth.svg';
import BackersNr from './assets/images/NR.png';
import BackersFocus from './assets/images/focus.svg';
import BackersHaskey from './assets/images/haskey.svg';
import BackersHoubi from './assets/images/huobi.png';
import BackersAsc from './assets/images/asc.svg';
import hainPic from './assets/images/hain.svg';
import peckPic from './assets/images/peck.svg';
import csPic from './assets/images/cs.png';
import mbPic from './assets/images/mixbytes.svg';
import SpearIcon from './assets/images/spear.svg';
import ShieldIcon from './assets/images/shield.svg';
import DiverBG from './assets/images/bg-2.png';
import Fullpage, { FullpageSection, FullPageSections } from '@ap.cx/react-fullpage';

// log
const AppWrapper = styled.div``;
const Header = styled.header`
  width: 100%;
  height: 100px;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  backdrop-filter: blur(60px);
  z-index: 2;
  > div {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    > div:first-child {
      flex: 1;
      height: 100px;
      align-items: center;
      display: flex;
    }
    > a {
      color: #fff;
      line-height: 100px;
      margin-left: 30px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      opacity: 0.86;
      &:hover {
        opacity: 0.7;
      }
      > button {
        height: 34px;
        border: 0;
        border-radius: 4px;
        background-image: linear-gradient(to right, #0381db, #04b0d1);
        width: 120px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1024px) {
    height: 55px;
    padding: 0 12px;
    background: rgba(0, 0, 0, 1);
    > div {
      > div {
        &:first-child {
          height: 55px;
        }
        img {
          height: 50px;
        }
      }
      > a {
        font-size: 12px;
        margin-left: 10px;
        line-height: 55px;
      }
    }
  }
`;

const Top = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  margin: 0 auto;
  /* backdrop-filter: blur(0) !important; */
  .sm-wrapper {
    margin-left: 50px;
    line-height: 10px;
    > a {
      margin-left: 15px;
      &:hover {
        opacity: 0.8;
      }
    }
    @media (max-width: 1024px) {
      margin-left: 0px;
      margin-top: 50px;
    }
  }
  > div > div {
    display: flex;
    align-items: center;
    margin-top: 70px;
    @media (max-width: 1024px) {
      display: block;
      text-align: center;
    }
  }
  button {
    height: 40px;
    border: 0;
    border-radius: 4px;
    background-image: linear-gradient(to right, #0381db, #04b0d1);
    width: 140px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      opacity: 0.8;
    }
  }
  p {
    font-size: 32px;
    line-height: 40px;
    margin: 20px 0 0 10px;
    font-weight: 400;
    &:first-child {
      font-weight: 700;
      font-size: 42px;
      margin-bottom: 40px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
      line-height: 45px;
      margin: 20px 0 0 0px;
    }
  }
  video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
    object-fit: cover;
    height: 110%;
    @media (max-width: 1024px) {
      height: 130%;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 20px 20px;
    text-align: center;
    margin: 150px auto 50px;
    display: flex;
  }
`;
const Backers = styled.div`
  // background-image: url(${DiverBG});
  background-size: cover;
  height: 100vh;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin-bottom: 65px;
  }
  > div {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    padding: 0 20px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px 50px;
    align-items: center;
    justify-items: center;
  }
  @media (max-width: 1024px) {
    padding: 40px 0;
    > div {
      width: 100%;
      grid-gap: 25px 0px;
      grid-template-columns: repeat(2, 1fr);
      .b1 {
        height: 39px;
      }
      .b2 {
        height: 45.5px;
      }
      .b3 {
        height: 51.2px;
      }
      .b4 {
        height: 57px;
      }
      .b5 {
        height: 33px;
      }
      .b6 {
        height: 18px;
      }
      .b7 {
        height: 45px;
      }
      .b8 {
        height: 16.5px;
      }
      .b9 {
        height: 16.5px;
      }
      .b10 {
        height: 64px;
      }
    }
  }
`;
const SpearAndShield = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0 0;
  @media (max-width: 1024px) {
    padding: 40px 0;
  }
  h2 {
    span:first-child {
      color: rgba(0, 227, 172, 0.6);
    }
    span:last-child {
      color: #89353b;
    }
  }
  p {
    font-size: 20px;
    width: 580px;
    font-weight: 500;
    opacity: 1;
    &:first-child {
      width: auto;
    }
    @media (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
      font-size: 20px;
    }
  }
  > .content {
    max-width: 1160px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    color: #a0a0a0;
    text-align: center;
    @media (max-width: 1024px) {
      padding: 0 20px;
      display: block;
      text-align: center;
      margin: 40px auto;
      p {
        //text-align: left;
      }
    }
  }
`;

const IndexDesc = styled.p`
  span {
    display: inline;
    font-size: 32px;
    @media (max-width: 800px) {
      display: block;
    }
  }
`;

function App() {
  return (
    <AppWrapper>
      <Header>
        <div>
          <div>
            <img height="70px" src={NewLogo} alt="loho" />
          </div>
          <a target={'_blank'} href={'https://docs.divergence-protocol.com'} rel={'noreferrer'}>
            Docs
          </a>
          <a target={'_blank'} href={'/diver_v1AMM_paper.pdf'} rel={'noreferrer'}>
            Whitepaper
          </a>
        </div>
      </Header>
      <Fullpage
        onChange={e => {
          console.log(e);
          if (e.number > 1) {
            document.getElementById('animation').classList.add('active');
          } else {
            document.getElementById('animation').classList.remove('active');
          }
        }}
      >
        <FullPageSections>
          <FullpageSection>
            <Top>
              <div>
                <p>Decentralized Crypto Options</p>
                <IndexDesc>
                  <span>No frills. </span>
                  <span>Ride and thrive on&nbsp;</span>
                  <span>digital asset volatility</span>
                </IndexDesc>
                <div>
                  <a href="https://app.divergence-protocol.com" target="_blank" rel="noreferrer">
                    <button className="active">Launch App</button>
                  </a>
                  <div className="sm-wrapper">
                    <a href="https://twitter.com/divergencedefi" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={Twitter} alt="twitter" />
                    </a>
                    <a href="https://t.me/divergenceprotocol" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={Telegram} alt="telegram" />
                    </a>
                    <a href="https://discord.gg/RkYxnNkc8H" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={Discord} alt="discord" />
                    </a>
                    <a href="https://medium.com/divergence-protocol" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={Medium} alt="medium" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC1P8arVH_nvOFudbtXs5i8A" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={Youtube} alt="youtube" />
                    </a>
                    <a href="https://github.com/DivergenceProtocol" target="_blank" rel="noreferrer">
                      <img width="26px" height="26px" src={GitHub} alt="github" />
                    </a>
                  </div>
                </div>
              </div>
            </Top>
          </FullpageSection>
          <FullpageSection>
            <div className={'center'}>
              <div className={'h1 text-center'}>Options Evolved</div>
              <div className={'text-center h2'} style={{ margin: '5px 0' }}>
                For the DeFi warriors
              </div>
              <div className={'text-center text'}>
                <span className={'mobile-block'}>Get paid 1 collateral&nbsp;</span>
                <span className={'mobile-block'}>if you're right about the underlying asset price.</span>
              </div>
              <div className={'text-center text'}>Up to 99x return per option.</div>
              <div style={{ margin: '40px auto 0' }} className={'flex flex-col options-icons'}>
                <div className={'options-icons-left option-1'}>
                  <div className={'flex'}>
                    <div style={{ marginRight: '5px' }}>
                      <div style={{ margin: '2px 0', fontWeight: 600 }}>SPEAR</div>
                      <div style={{ color: '#bbb' }}>Digital Call</div>
                      <div style={{ color: '#bbb' }}>Profit from price rises</div>
                    </div>
                    <img height={46} width={46} src={SpearIcon} alt="k1" />
                  </div>
                </div>
                <div className={'option-2'} style={{ marginTop: '10px', color: '#999', fontWeight: 800 }}>
                  vs
                </div>
                <div className={'option-3'} style={{ marginTop: '20px' }}>
                  <div className={'flex items-start'}>
                    <img height={46} width={46} src={ShieldIcon} alt="k1" />
                    <div style={{ marginLeft: '5px' }}>
                      <div style={{ margin: '2px 0', fontWeight: 600 }}>SHIELD</div>
                      <div style={{ color: '#bbb' }}>Digital Put</div>
                      <div style={{ color: '#bbb' }}>Profit from price declines</div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '80px'
                }}
              >
                <div className={'text-center option-item'}>
                  <div className={'font-bold'}>SWAP</div>
                  <p className={'text'}>Buy from your own wallet.</p>
                  <p className={'text'}>Claim reward at settlement.</p>
                </div>
                <div className={'text-center option-item'}>
                  <div className={'font-bold'}>PROVIDE LIQUIDITY</div>
                  <p className={'text'}>Sell at your custom price range.</p>
                  <p className={'text'}>Earn premiums, fees, and more:)</p>
                </div>
                <div className={'text-center option-item'}>
                  <div className={'font-bold'}>Earn</div>
                  <p className={'text'}>Pick an ERC20 collateral you like</p>
                  <p className={'text'}>to trade and receive as payout.</p>
                </div>
              </div>
            </div>
          </FullpageSection>
          <FullpageSection>
            <SpearAndShield>
              <h2>$Divers for Divers</h2>
              <div className={'content'}>
                <div className={'text-center'}>
                  <p>A utility token that boosts income,</p>
                  <p>enhances governance, and empowers builders.</p>
                </div>
              </div>
              <div className="flex justify-center items-center gap-4" style={{ marginTop: '15px' }}>
                <a
                  rel={'noreferrer'}
                  target={'_blank'}
                  href="https://etherscan.io/token/0xfb782396c9b20e564a64896181c7ac8d8979d5f4
"
                >
                  <img src="/etherscan.png" alt="etherscan" width={28} height={28} />
                </a>
                <a
                  rel={'noreferrer'}
                  target={'_blank'}
                  href="https://app.uniswap.org/explore/pools/ethereum/0xB5851eB5ec92Afff01840B90eD3BeA51a77b3CC6"
                >
                  <img src="/uni.svg" alt="etherscan" width={40} height={40} />
                </a>
                <a rel={'noreferrer'} target={'_blank'} href="https://www.coingecko.com/en/coins/divergence-protocol?locale=en">
                  <img src="/coingecko.png" alt="etherscan" width={28} height={28} />
                </a>
              </div>
            </SpearAndShield>
          </FullpageSection>
          <FullpageSection>
            <Backers>
              <h2>Backed By</h2>
              <div>
                <img className={'b1'} height="54.6px" src={BackersMecha} alt="BACKERS" />
                <img className={'b2'} height="63.7px" src={BackersKr1} alt="BACKERS" />
                <img className={'b3'} height="72.1px" src={BackersP2p} alt="BACKERS" />
                <img className={'b4'} height="79.8px" src={BackersArr} alt="BACKERS" />
                <img className={'b5'} height="46.2px" src={BackersOrth} alt="BACKERS" />
                <img className={'b6'} height="26.6px" src={BackersNr} alt="BACKERS" />
                <img className={'b7'} height="63px" src={BackersFocus} alt="BACKERS" />
                <img className={'b8'} height="23px" src={BackersHaskey} alt="BACKERS" />
                <img className={'b9'} height="23px" src={BackersHoubi} alt="BACKERS" />
                <img className={'b10'} height="89.6px" src={BackersAsc} alt="BACKERS" />
              </div>
            </Backers>
          </FullpageSection>
        </FullPageSections>
      </Fullpage>

      <div className={'footer'}>
        <div className="sm-wrapper">
          <a className={'link'} href="https://docs.divergence-protocol.com/legal/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          <a className={'link'} href="https://docs.divergence-protocol.com/legal/risk-disclosure" target="_blank" rel="noreferrer">
            Risk Disclosure
          </a>
          <a className={'link'} href="mailto:contact@divergence-protocol.com" target="_blank" rel="noreferrer">
            Contact
          </a>
        </div>
        <div>
          <div className="flex items-center gap-4">
            <span className={'footer-subtitle'}>Audited by</span>
            <a href="https://mixbytes.io" target="_blank" rel="noreferrer" style={{ height: '18px' }}>
              <img className="hainPic" height="18px" src={mbPic} alt="hainPic" />
            </a>
            <a href="https://chainsecurity.com" target="_blank" rel="noreferrer" style={{ height: '28px' }}>
              <img className="peckPic" height="28px" src={csPic} alt="peckPic" />
            </a>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}

export default App;
